<template>
  <div v-if="active" class="loading-container is-relative">
    <b-loading :is-full-page="false" :active="active"></b-loading>
  </div>
</template>

<script>
export default {
  name: "ListLoadingIndicator",

  props: ["active", "backgroundColor"],
};
</script>

<style scoped lang="scss">
.loading-container {
  width: 100%;
  max-height: 60px;
  height: 60px;
  margin-top: 20px;
}
</style>
