import VueI18n from "vue-i18n";
import dayjs from '@/shared/utils/day';
import "@/shared/utils/dayjs-locales";

import { detectBrowserLanguage } from "@/widgets/shared/utils";

// Preload all locales on module import
// Solves issue with loading locales multiple times
const locales = require.context("./", false, /\.json$/i);
const messages = Object.create(null);

locales.keys().forEach(filename => {
  const matched = filename.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
    const key = matched[1];
    messages[key] = locales(filename);
  }
});

// Prepare locale for the app
const locale = detectBrowserLanguage();
const fallbackLocale = "en";

dayjs.locale(locale);

export function createLocalization() {
  return new VueI18n({
    locale,
    fallbackLocale,
    messages
  });
}
