<template>
  <div>
    <div v-if="state === Constants.FRIEND_NOT_FRIEND">
      <div v-if="!isRequesting" @click="addToFriends(userUuid)" class="button is-rounded is-expanded is-success">
        {{ $t("friends.add_to_friends") }}
      </div>
      <b-button class="is-rounded is-success" v-else loading>
        {{ $t("friends.add_to_friends") }}
      </b-button>
    </div>

    <div v-else-if="state === Constants.FRIEND_ACCEPTED">
      <div v-if="!isRequesting" @click="removeFromFriends(userUuid)" class="button is-rounded is-expanded is-danger">
        {{ $t("friends.remove_from_friends") }}
      </div>
      <b-button class="is-rounded is-danger" v-else loading>
        {{ $t("friends.remove_from_friends") }}
      </b-button>
    </div>

    <div v-else-if="state === Constants.FRIEND_REQUESTED">
      <div v-if="!isRequesting" @click="acceptInvitation(userUuid)"
           class="button is-rounded is-expanded is-primary has-text-white">
        {{ $t("friends.invitation_accept_long") }}
      </div>
      <b-button class="is-rounded is-primary" v-else loading>
        {{ $t("friends.invitation_accept_long") }}
      </b-button>
    </div>

    <div v-else-if="state === Constants.FRIEND_PENDING">
      <div v-if="!isRequesting" @click="rejectInvitation(userUuid)"
           class="button is-rounded is-expanded is-light has-background-grey has-text-white">
        {{ $t("friends.invitation_cancel") }}
      </div>
      <b-button class="is-rounded is-light has-background-grey" v-else loading>
        {{ $t("friends.invitation_cancel") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import Constants from "@/web/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FriendsButton",

  props: {
    state: {
      type: String,
      default: "",
    },
    userUuid: {
      type: String,
    },
  },

  computed: {
    ...mapGetters("friends", ["isRequesting"]),
    Constants: () => Constants,
  },

  methods: {
    ...mapActions("friends", ["acceptInvitation", "rejectInvitation", "removeFromFriends", "addToFriends"]),
  },
};
</script>

<style scoped></style>
