<template>
  <div class="box selectable">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "BMComponentListItem",

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    title() {
      return this.component.label;
    },

    type() {
      return this.component.additional_info.business_matching_type;
    },
  },

  methods: {},
};
</script>

<style scoped></style>
