<template>
  <div class="field">
    <label class="label has-text-primary">{{ label }}</label>
    <div class="columns mb-0 pb-0 is-mobile is-gapless mt-1">
      <div class="column is-one-quarter mr-1">
        <number-phone-prefix-select @input="selectPhonePrefix"></number-phone-prefix-select>
      </div>
      <div class="column">
        <div class="control">
          <input class="input is-simple" type="tel" :placeholder="$t('common.concact_phone')" v-model="phoneNumber" />
        </div>
      </div>
    </div>
    <template v-if="errorsVisible">
      <p v-if="requiredError" class="help is-danger">
        {{ $t("common.field_required_error") }}
      </p>
      <p v-else-if="invalidError" class="help is-danger">
        {{ $t("edit_profile.phone_number_invalid") }}
      </p>
    </template>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import NumberPhonePrefixSelect from "@/web/components/forms/NumberPhonePrefixSelect";

export default {
  name: "RegisterPhoneNumberInput",

  components: {
    NumberPhonePrefixSelect,
  },

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      phonePrefix: "",
      phoneNumber: "",
    };
  },

  computed: {
    fieldId() {
      return this.fieldData.id;
    },

    requiredError() {
      const validationKey = FormBuilderConstants.VALIDATIONS.REQUIRED;
      return validationKey in this.validation && !this.validation[validationKey];
    },

    invalidError() {
      const validationOptionalKey = FormBuilderConstants.VALIDATIONS.PHONE_NUMBER_OPTIONAL;
      const validationRequiredKey = FormBuilderConstants.VALIDATIONS.PHONE_NUMBER_REQUIRED;
      if (validationOptionalKey in this.validation) {
        return !this.validation[validationOptionalKey];
      } else if (validationRequiredKey in this.validation) {
        return !this.validation[validationRequiredKey];
      } else {
        return false;
      }
    },

    label() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_label`);
    },

    currentValue() {
      return {phonePrefix: this.phonePrefix, phoneNumber: this.phoneNumber };
    }
  },

  methods: {
    selectPhonePrefix(input) {
      this.phonePrefix = input;
    }
  },

  watch: {
    currentValue(newValue) {
      if (newValue.phonePrefix && newValue.phoneNumber) {
        this.$emit("input", newValue);
      } else {
        this.$emit("input", null);
      }

      this.$emit("form-input");
    },
  },
};
</script>

<style scoped></style>
