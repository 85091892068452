import { mapGetters } from "vuex";
import Constants from "@/web/constants";
import RedirectMixin from "@/web/mixins/RedirectMixin";

export default {
  mixins: [RedirectMixin],

  computed: {
    ...mapGetters("auth", ["isAuthorized"])
  },

  watch: {
    isAuthorized(value) {
      if (!value) {
        this.nextRedirect({ name: Constants.ROUTE_MAIN_INTRODUCTION });
      }
    }
  },

  mounted() {
    if (!this.isAuthorized) {
      const query = { returnUrl: this.$route.fullPath };
      this.nextRedirect({ name: Constants.ROUTE_LOGIN, query });
    }
  }
};
