<template>
  <div class="box px-0 py-0" :class="boxClasses">
    <div class="columns is-gapless">
      <div class="column">
        <!-- General information -->
        <article class="media" @click="toggle" :style="{ cursor: cursorStyle }" :class="cardClasses">
          <div class="media-left has-text-right" :class="isSmallClass" v-if="!hideSessionTime">
            <div class="agenda-session-date" v-if="sessionTimeWithDate" :class="timeTextClasses">{{ formatDate(startTime) }}</div>
            <div class="agenda-session-start-time" :class="timeTextClasses">{{ formatTime(startTime) }}</div>
            <div class="agenda-session-end-time" :class="timeTextClasses">{{ formatTime(endTime) }}</div>
          </div>

          <div class="media-content">
            <tag-content :color="tagColor" :is-small="isSmall">
              <slot></slot>
            </tag-content>
          </div>

          <div class="media-right">
            <slot name="actions"></slot>
          </div>
        </article>

        <!-- Collapseable content -->
        <b-collapse v-model="isShown">
          <div class="agenda-content">
            <hr class="mt-0 mb-2" />
            <slot name="collapse"></slot>
          </div>
        </b-collapse>
      </div>

      <div class="column is-narrow has-background-primary">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TagContent from "@/shared/components/agenda/TagContent";
import {composeAgendaSessionTime, composeDayDate} from "@/shared/utils";
import { mapState } from "vuex";
import Constants from "@/widgets/shared/constants";

export default {
  name: "AgendaCard",

  components: {
    TagContent,
  },

  props: ["startTime", "endTime", "color", "hasCollapse", "isSmall", "selectable", "hideSessionTime", "isHighlighted", "sessionTimeWithDate"],

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen || !this.hasCollapse) {
        this.$emit("open", this.isOpen);
      }
    },

    formatTime(date) {
      return composeAgendaSessionTime(new Date(date).getTime(), this.timezone);
    },

    formatDate(date) {
      return composeDayDate(new Date(date).getTime(), this.timezone);
    },
  },

  computed: {
    ...mapState(["timezone", "theme"]),

    isShown() {
      return this.isOpen && !!this.hasCollapse;
    },

    cursorStyle() {
      if (this.hasCollapse || this.selectable) {
        return "pointer";
      } else {
        return "default";
      }
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },

    boxClasses() {
      return {
        'selectable': this.selectable,
        'is-highlighted': this.isHighlighted,
      };
    },

    tagColor() {
      if (this.theme === Constants.WIDGET_THEME_TYPES.SCIENTIFIC) {
        return "#E0E0E0";
      } else {
        return this.color;
      }
    },

    cardClasses() {
      return {
        "is-vcentered": this.theme !== Constants.WIDGET_THEME_TYPES.SCIENTIFIC,
        "is-small": this.isSmall,
      };
    },

    timeTextClasses() {
      return {
        "is-scientific": this.isScientificTheme,
        "is-small": this.isSmall,
      };
    },

    isScientificTheme() {
      return this.theme === Constants.WIDGET_THEME_TYPES.SCIENTIFIC;
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  padding: 24px;
  margin: -24px;
  min-width: 0;

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.box {
  background: var(--agenda-session-item-background-color);
}

.media {
  padding: 24px;

  &.is-small {
    padding: 12px;
  }
}

.agenda-content {
  padding: 0 24px 24px 24px;

  > hr {
    background-color: var(--agenda-card-divider-color);
  }
}

.media-left {
  margin-right: 1rem;

  &.is-small {
    margin-right: 0.5rem;
  }
}
</style>
