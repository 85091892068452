import dayjs from '@/shared/utils/day';

export function pluckError(err) {
  let error = pluckErrorMessage(err && err.response);
  error = error || (err && err.message);
  error = error || (err && err.toString());
  error = error || "Request failed";

  return error;
}

export function pluckErrorCode(err) {
  if (typeof err === "string" || !err.response) {
    return null;
  }
  const {data} = err.response;
  if (data && data.errors && Array.isArray(data.errors)) {
    const error = data.errors[0];
    if (error && error.code) {
      return error.code;
    }
  }

  return null;
}

function pluckErrorMessage(response) {
  if (!response || typeof response === "string") {
    return response;
  }
  const {data} = response;
  if (data && data.errors && Array.isArray(data.errors)) {
    const error = data.errors[0];
    if (error && error.message) {
      return error.message;
    }
  }

  return null;
}

const careerDayI = 54807;
const careerDayII = 54808;

const womenInTechFirstBlock = [54767, 54771, 54781, 54789, 54768, 54772, 54782, 54790, 54969, 54971];

//60min block
const womenInTech60min = [54783, 54786, 54784, 54787, 54785, 54788];

//90min block
const womenInTech90min = [54773, 54769, 54770, 54780, 54973, 54975];

export function isWomenInTechSoftSkillFirst(agendaSessionId) {
  return womenInTechFirstBlock.includes(agendaSessionId);
}

export function isWomenInTechSoftSkillSecond(agendaSessionId) {
  return womenInTech60min.includes(agendaSessionId) || womenInTech90min.includes(agendaSessionId);
}

export function isWomenInTechTechSkills(agendaSession) {
  return agendaSession.tag_agenda_path.id === 22159;
}

export function isWomenInTechCareerDayI(agendaSessionId) {
  return agendaSessionId === careerDayI;
}

export function isWomenInTechCareerDayII(agendaSessionId) {
  return agendaSessionId === careerDayII;
}

export function isSessionOverlapping(session, mySessions) {
  if(womenInTech60min.includes(session.id)) {
    if(mySessions.some(mySession => womenInTechFirstBlock.includes(mySession.id))) {
      throw new Error("Nie możesz zarejestrować się na te warsztaty. W tym bloku godzinowym jedyną dostępną opcją są Targi Kariery II");
    }
    if(mySessions.some(mySession => womenInTech90min.includes(mySession.id))) {
      throw new Error("Nie możesz zarejestrować się na te warsztaty. Rozpoczęłaś już zapisy na sesję 2 x 90min.");
    }
  } else if(womenInTech90min.includes(session.id)) {
    if(mySessions.some(mySession => womenInTechFirstBlock.includes(mySession.id))) {
      throw new Error("Nie możesz zarejestrować się na te warsztaty. W tym bloku godzinowym jedyną dostępną opcją są Targi Kariery II");
    }
    if(mySessions.some(mySession => womenInTech60min.includes(mySession.id))) {
      throw new Error("Nie możesz zarejestrować się na te warsztaty. Rozpoczęłaś już zapisy na sesję 3 x 60min.");
    }
  } else if(session.id === careerDayI) {
    if(mySessions.some(mySession => mySession.id === careerDayII)) {
      throw new Error("Nie możesz zarejestrować się na te warsztaty, ponieważ rozpoczęłaś już zapisy na Targi Kariery II. Warsztaty niecierpliwie czekają aż je wybierzesz! :)");
    }
  } else if(session.id === careerDayII) {
    if(mySessions.some(mySession => mySession.id === careerDayI)) {
      throw new Error("Nie możesz zarejestrować się na te warsztaty. Rozpoczęłaś już zapisy na Targi Kariery I. Warsztaty niecierpliwie czekają aż je wybierzesz! :)");
    }
  } else if(womenInTechFirstBlock.includes(session.id)) {
    if(mySessions.some(mySession => womenInTech60min.includes(mySession.id) || womenInTech90min.includes(mySession.id))) {
      throw new Error("Nie możesz zarejestrować się na te warsztaty. W tym bloku godzinowym jedyną dostępną opcją są Targi Kariery I");
    }
  }

  const sessionStartMoment = dayjs(session.time_start);
  const sessionEndMoment = dayjs(session.time_end);


  if(mySessions.some(mySession => {
    const mySessionStartMoment = dayjs(mySession.time_start);
    const mySessionEndMoment = dayjs(mySession.time_end);
    return sessionStartMoment.isBetween(mySessionStartMoment, mySessionEndMoment)
      || sessionEndMoment.isBetween(mySessionStartMoment, mySessionEndMoment)
      || mySessionStartMoment.isBetween(sessionStartMoment, sessionEndMoment)
      || mySessionEndMoment.isBetween(sessionStartMoment, sessionEndMoment)
      || session.time_start === mySession.time_start;
  })) {
    throw new Error("Nie tak łapczywie! Nie możesz zarejestrować się na to wydarzenie ponieważ pokrywa się z innymi, na które już jesteś zapisana :)");
  }
}

export default {
  pluckError,
  pluckErrorCode,
  isSessionOverlapping,
  isWomenInTechSoftSkillFirst,
  isWomenInTechSoftSkillSecond,
  isWomenInTechTechSkills,
  isWomenInTechCareerDayI,
  isWomenInTechCareerDayII,
};
