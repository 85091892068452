<template>
  <div class="contact-link">
    <div class="contact-icon">
      <email-icon v-if="type === 'email'"></email-icon>
      <phone-icon v-if="type === 'phone'"></phone-icon>
    </div>
    <a :href="targetUrl" rel="noopener noreferrer">{{ target }}</a>
  </div>
</template>

<script>
import EmailIcon from "@/assets/contact/email-icon.svg";
import PhoneIcon from "@/assets/contact/phone-icon.svg";

const urlPrefix = {
  phone: "tel:",
  email: "mailto:"
};

export default {
  components: {
    EmailIcon,
    PhoneIcon
  },

  props: ["type", "target"],

  computed: {
    targetUrl() {
      const prefix = urlPrefix[this.type] || "";
      return `${prefix}${this.target}`;
    }
  }
};
</script>

<style lang="scss">
.contact-link {
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  min-width: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.contact-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;

  svg {
    fill: #828282;
    display: block;
    width: 20px;
    height: 20px;
  }

  &:after {
    content: "";
    margin-left: 10px;
    border-right: solid 1px #bdbdbd;
    display: block;
    height: 14px;
  }
}
</style>
