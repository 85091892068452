<template>
  <section>
    <div class="field mb-1">
      <dropdown-select :items="numberPhonePrefixes" :value="selected" :multiple="false" @input="select">
        <template #dropdown="{ item }">
          <div class="media">
            <div class="has-flag media-left">
              <country-flag :code="item.region"></country-flag>
            </div>
            <div class="media-content">
              <small>
                {{ item.phonePrefix }}
              </small>
            </div>
          </div>
        </template>

        <template #placeholder>
          <div class="has-text-secondary">
            {{ $t("auth.phone_number_prefix_placeholder") }}
          </div>
        </template>
      </dropdown-select>
    </div>
  </section>
</template>

<script>
import CountryFlag from "@/web/components/shared/CountryFlag";
import libPhone from "google-libphonenumber";
import DropdownSelect from "@/shared/components/DropdownSelect";

export default {
  name: "NumberPhonePrefixSelect",

  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  components: {
    DropdownSelect,
    CountryFlag,
  },

  data() {
    if(this.value)  {
      const phoneUtil = libPhone.PhoneNumberUtil.getInstance();
      let arr = phoneUtil
        .getSupportedRegions()
        .map(region => {
          return {
            region: region,
            phonePrefix: `+${phoneUtil.getCountryCodeForRegion(region).toString()}`,
          };
        })
        .sort((a, b) => {
          if(a.region === 'PL') {
            return -1;
          } else if(b.region === 'PL') {
            return 1;
          } else {
            return a.phonePrefix - b.phonePrefix;
          }
        });
      let index = arr.findIndex(el => el.phonePrefix === this.value);
      this.$emit("input", arr[index]);
      return {
        selected: index,
      };
    } else {
      return {
        selected: null,
      };
    }

  },

  computed: {
    numberPhonePrefixes() {
      const phoneUtil = libPhone.PhoneNumberUtil.getInstance();
      return phoneUtil
        .getSupportedRegions()
        .map(region => {
          return {
            region: region,
            phonePrefix: `+${phoneUtil.getCountryCodeForRegion(region).toString()}`,
          };
        })
        .sort((a, b) => {
          if(a.region === 'PL') {
            return -1;
          } else if(b.region === 'PL') {
            return 1;
          } else {
            return a.phonePrefix - b.phonePrefix;
          }
        });
    },
  },

  methods: {
    select(index) {
      this.selected = index;
      this.$emit("input", this.numberPhonePrefixes[index]);
    },
  },
};
</script>

<style scoped lang="scss">
.has-flag img {
  display: block;
  width: 18px;
  height: auto;
}

.has-flag {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
