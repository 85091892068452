<template>
  <div class="field">
    <label class="label has-text-primary">{{ label }}</label>
    <div class="control">
      <datepicker input-class="input is-simple" calendar-class="calendar" v-model="value" :placeholder="label" :language="datePickerLocales"></datepicker>
    </div>
    <template v-if="errorsVisible">
      <p v-if="requiredError" class="help is-danger">{{ $t("common.field_required_error") }}</p>
    </template>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import Datepicker from 'vuejs-datepicker';
import * as DatePickerLocales from 'vuejs-datepicker/dist/locale';
import { mapGetters } from "vuex";

export default {
  name: "RegisterDateInput",

  components: {
    Datepicker,
  },

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      value: "",
    };
  },

  computed: {
    ...mapGetters("locales", ["currentLocale"]),

    fieldId() {
      return this.fieldData.id;
    },

    datePickerLocales() {
      return DatePickerLocales[this.currentLocaleCountryCode];
    },

    requiredError() {
      const validationKey = FormBuilderConstants.VALIDATIONS.REQUIRED;
      return validationKey in this.validation && !this.validation[validationKey];
    },

    label() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_label`);
    },

    currentLocaleCountryCode() {
      return this.currentLocale ? this.currentLocale.countryCode : 'en';
    },
  },

  watch: {
    value(newValue) {
      this.$emit("input", newValue);
      this.$emit("form-input");
    },
  },
};
</script>

<style scoped></style>
