<template>
  <div class="box is-banner is-borderless is-radius-large has-background-primary px-4 py-5" :style="backgroundStyle">
    <div class="box banner-logo is-borderless is-inline-flex has-logo is-clipped mb-1 my-2">
      <figure v-if="logoUrl" class="image is-centered is-80x80">
        <img :src="logoUrl" />
      </figure>
    </div>

    <div v-if="tags.length" class="exhibitor-details-tags-list with-wrap mb-1">
      <div v-for="tag in tags" :key="`exh-tag-${tag.id}`">
        <tag-content :color="tag.hex" :clickable="false">
          <template>
            {{ tag.value.toUpperCase() }}
          </template>
        </tag-content>
      </div>
    </div>

    <div class="content">
      <h1 class="title is-2 has-text-white">{{ title }}</h1>
      <p class="subtitle is-5 has-text-white">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
import TagContent from "@/widgets/shared/components/TagContent";

export default {
  name: "ExhibitorBanner",
  components: { TagContent },
  props: ["exhibitor", "roundedTopBorders"],

  computed: {
    backgroundStyle() {
      const imageUrl = this.backgroundUrl;
      const overlay = `linear-gradient(to top, rgba(0,0,0,.5), rgba(0,0,0,0))`;
      return {
        backgroundImage: this.backgroundUrl ? `${overlay}, url("${imageUrl}")` : null,
        borderTopLeftRadius: this.roundedTopBorders ? "6px" : 0,
        borderTopRightRadius: this.roundedTopBorders ? "6px" : 0,
      };
    },

    backgroundUrl() {
      const image = this.exhibitor.vip_picture;
      return image && image.file_url;
    },

    logoUrl() {
      const picture = this.exhibitor.picture;
      if (picture) {
        return picture && picture.file_thumbnail_200_url;
      } else {
        return require("@/assets/photo_placeholder.png");
      }
    },

    title() {
      return this.exhibitor.name;
    },

    subtitle() {
      return this.exhibitor.scope_description;
    },

    tags() {
      return this.exhibitor.tags_exhibitors;
    },
  },
};
</script>

<style lang="scss" scoped>
.box.is-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 420px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-logo {
  width: 80px;
  height: 80px;
}

.image {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
