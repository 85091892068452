<template>
  <div class="box px-2 py-2">
    <div class="media is-vcentered">
      <div class="media-left mr-1">
        <slot name="left">
          <file-icon class="file-icon"></file-icon>
        </slot>
      </div>

      <div class="media-content">
        <slot></slot>
      </div>

      <div class="media-right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import FileIcon from "@/assets/icons/file.svg";

export default {
  name: "Attachment",

  components: {
    FileIcon
  }
};
</script>

<style lang="scss" scoped>
.file-icon {
  fill: #4f4f4f;
  display: block;
  width: 24px;
  height: 24px;
}
</style>
