<script>
// Load all flags available under "src/assets/flags" directory
const requireFlags = require.context("@/assets/flags", false, /\.webp$/);
const flags = requireFlags.keys().reduce((acc, key) => {
  const code = key.match(/[\w]{2}/)[0].toLowerCase();
  return Object.assign(acc, {
    [code]: /* webpackChunkName: flags */ requireFlags(key),
  });
}, {});

export default {
  functional: true,

  props: {
    code: {
      type: String,
      default: null,
    },
  },

  render(h, { props }) {
    const countryCode = normalizeCode(props.code);
    return h("img", { attrs: { src: flags[countryCode] } });
  },
};

function normalizeCode(code) {
  return `${code || ""}`.toLowerCase();
}
</script>
