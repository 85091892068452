<template>
  <a class="social-link" target="_blank" :href="target" :class="`is-${type}`" rel="noopener noreferrer">
    <social-facebook-icon v-if="type === 'facebook'"></social-facebook-icon>
    <social-linkedin-icon v-if="type === 'linkedin'"></social-linkedin-icon>
    <social-x-icon v-if="type === 'x' || type === 'twitter'"></social-x-icon>
    <social-instagram-icon v-if="type === 'instagram'"></social-instagram-icon>
    <social-youtube-icon v-if="type === 'youtube'"></social-youtube-icon>
    <social-google-icon v-if="type === 'google'"></social-google-icon>
    <social-apple-icon v-if="type === 'apple'"></social-apple-icon>
    <div class="www-button" v-if="type === 'www'">WWW</div>
  </a>
</template>

<script>
import SocialFacebookIcon from "@/assets/contact/social-facebook-icon.svg";
import SocialLinkedinIcon from "@/assets/contact/social-linkedin-icon.svg";
import SocialXIcon from "@/assets/contact/social-x-icon.svg";
import SocialInstagramIcon from "@/assets/contact/social-instagram-icon.svg";
import SocialYoutubeIcon from "@/assets/contact/social-youtube-icon.svg";
import SocialGoogleIcon from "@/assets/icon_google.svg";
import SocialAppleIcon from "@/assets/icon_apple.svg";

export default {
  name: "SocialLink",

  components: {
    SocialFacebookIcon,
    SocialLinkedinIcon,
    SocialXIcon,
    SocialInstagramIcon,
    SocialYoutubeIcon,
    SocialGoogleIcon,
    SocialAppleIcon,
  },

  props: ["target", "type"],
};
</script>

<style lang="scss" scoped>
.social-link {
  border-radius: 2048px;
  font-size: 48px;
  display: block;

  > svg {
    padding: 0.25em;
    box-sizing: content-box;
    display: block;
    width: 0.5em;
    height: 0.5em;
  }
}

// Color variants
$social-links: (
  facebook: #395ca9,
  linkedin: #006cac,
  twitter: #1da1f2,
  instagram: #fa7e1e,
  youtube: #ff0000,
  google: #ffffff,
  apple: #040507,
);

@each $type, $color in $social-links {
  .social-link.is-#{$type} {
    background: $color;
    &:hover {
      background: darken($color, $amount: 5);
    }
  }
}

// Instagram has gradient background
.social-link.is-instagram,
.social-link.is-instagram:hover {
  background: linear-gradient(98.31deg, #feda75 6.52%, #fa7e1e 29.27%, #d62976 52.93%, #962fbf 72.95%, #4f5bd5 93.35%);
}

.social-link.is-www {
  background-color: #2b3138;
  font-size: 11px;
  display: flex;
  border-radius: 50%;
  line-height: 130%;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}

.social-link.is-google {
  box-shadow: -1px 0px 0px 0px #dbdbdb, 1px 0px 0px 0px #dbdbdb, 0px 1px 0px 0px #dbdbdb, 0px 0px 0px 1px #dbdbdb;
}

.social-link.is-apple:hover {
  background-color: lighten(#040507, 20%);
}

.www-button {
  color: white;
}
</style>
