export function initials(profile) {
  const a = ((profile.first_name && profile.first_name[0]) || "").toUpperCase();
  const b = ((profile.last_name && profile.last_name[0]) || "").toUpperCase();
  return `${a}${b}`;
}

export function fullName(profile) {
  return `${profile.first_name} ${profile.last_name}`;
}

export function positionAndCompany(profile) {
  const company = profile.company;
  const position = profile.position;

  if (company && position) {
    return position + ", " + company;
  } else if (company) {
    return company;
  } else if (position) {
    return position;
  } else {
    return "";
  }
}

export function buildSocialLinks(profile) {
  const socialLinks = [
    { type: "facebook", target: profile.facebook_profile },
    { type: "linkedin", target: profile.linked_profile },
    { type: "twitter", target: profile.twitter_profile },
    { type: "instagram", target: profile.instagram_profile },
    { type: "youtube", target: profile.youtube_profile },
  ];

  // Filter only non-empty social media links
  return socialLinks.filter(link => !!link.target);
}

export function buildContactList(profile) {
  let contacts = [];

  if (profile.email) {
    contacts = [...contacts, { type: "email", target: profile.email }];
  }

  if (profile.phone || profile.phone_number) {
    let phoneNumber = profile.phone || profile.phone_number;
    let phoneCountryCode = profile.phone_country_code ? profile.phone_country_code : "";
    if (phoneCountryCode && !phoneCountryCode.startsWith("+")) {
      phoneCountryCode = `+${phoneCountryCode}`;
    }
    let target = `${phoneCountryCode}${phoneNumber ? phoneNumber : ""}`;
    contacts = [...contacts, { type: "phone", target: target }];
  }

  return contacts;
}

export function buildContactModels(contacts) {
  return contacts.reduce(
    (acc, contact) => [
      ...acc,
      {
        uuid: contact.user_identity_token,
        picture: contact.picture,
        firstName: contact.first_name,
        lastName: contact.last_name,
        description: contact.position || contact.description,
        order: contacts.order,
        contacts: buildContactList(contact),
      },
    ],
    []
  );
}
