<template>
  <div class="columns is-multiline is-centered">
    <div v-for="(item, index) in timeSlots" :key="`timeslot-${index}`" class="column is-one-third">
      <b-m-time-slot :timezone="timezone" :time-slot="item" :selected="isSelected(item)"
                     @click.native="select(item)"></b-m-time-slot>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import BMTimeSlot from "@/web/components/businessmatching/BMTimeSlot";

export default {
  name: "BMTimeSlotSelect",

  components: {BMTimeSlot},

  props: {
    timeSlots: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedTimeSlot: null,
    };
  },

  computed: {
    ...mapState(["timezone"]),
  },

  methods: {
    select(timeSlot) {
      if (this.isSelected(timeSlot)) {
        this.selectedTimeSlot = null;
        this.$emit("input", null);
      } else {
        this.selectedTimeSlot = timeSlot;
        this.$emit("input", timeSlot);
      }
    },

    isSelected(timeSlot) {
      if (this.selectedTimeSlot) {
        return timeSlot.time_start === this.selectedTimeSlot.time_start && timeSlot.time_end === this.selectedTimeSlot.time_end;
      } else {
        return false;
      }
    },

    deselect() {
      this.selectedTimeSlot = null;
    },
  },
};
</script>

<style scoped></style>
