export function getCustomTranslation(object, fieldKey, defaultValue, namespace) {
  const translationNamespace = namespace || "custom";
  if (typeof object === "object" && object !== null && fieldKey in object && object[fieldKey]) {
    const translationKey = `${translationNamespace}.${object[fieldKey]}`;
    let translation = this.$t(translationKey);
    if (translation === translationKey) {
      return object[fieldKey];
    } else {
      return translation;
    }
  }
  return defaultValue;
}

export function getFieldValueOrDefault(object, fieldKey, defaultValue) {
  if (typeof object === "object" && object !== null && fieldKey in object) {
    return object[fieldKey];
  } else {
    return defaultValue;
  }
}

export function getCustomTranslationKey(object, fieldKey, namespace, defaultValue) {
  if (typeof object === "object" && object !== null && fieldKey in object) {
    return `${namespace}.${object[fieldKey]}`;
  } else {
    return defaultValue;
  }
}

export default {
  methods: {
    getCustomTranslation,
    getFieldValueOrDefault,
    getCustomTranslationKey,
  },
};
