export function entityIdsOf(collection) {
  return collection.map(entity => entity.id);
}

export function entityMapOf(collection) {
  return collection.reduce((acc, entity) => ({...acc, [entity.id]: entity}), {});
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
