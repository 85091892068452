<template>
  <profile-card v-bind="{ picture, firstName, lastName, description }">
    <template v-if="uuid && inboxEnabled && !isCurrentUser" #action>
      <div class="button is-text p-1" @click="openInbox">
        <message-icon class="message-icon-color"></message-icon>
      </div>
    </template>

    <div class="contacts mt-2" v-if="contacts && contacts.length">
      <template v-for="(contact, index) in contacts">
        <contact-link v-bind="contact" :key="index"></contact-link>
      </template>
    </div>
  </profile-card>
</template>

<script>
import MessageIcon from "@/assets/contact/message-icon.svg";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import ProfileCard from "@/web/components/shared/ProfileCard";
import ContactLink from "@/web/components/shared/ContactLink";
import {Constants} from "@/web/constants";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "ContactCard",

  components: {
    MessageIcon,
    ProfileCard,
    ContactLink,
  },

  props: ["picture", "firstName", "lastName", "description", "uuid", "contacts", "inboxEnabled"],

  mixins: [RedirectMixin],

  methods: {
    ...mapActions("inboxThreads", ["checkIfThreadExists"]),
    ...mapActions("sideMenu", ["openInboxBubbleWithThread", "openInboxBubbleWithUser"]),

    openInbox() {
      if (this.userUuid) {
        this.checkIfThreadExists({userId: this.uuid}).then(result => {
          if (result.data) {
            const threadId = result.data.id;
            this.openInboxBubbleWithThread(threadId);
          } else {
            this.openInboxBubbleWithUser(this.uuid);
          }
        });
        this.$emit("inbox-opened");
      } else {
        this.$root.openLoginModal();
      }
    },
  },

  computed: {
    ...mapState("currentUser", ["userUuid"]),

    isCurrentUser() {
      return this.uuid === this.userUuid;
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts {
  margin-top: auto;
}

.message-icon-color {
  fill: var(--primary-color);
}
</style>
