<template>
  <div class="speaker-view-container">
    <header class="view-header">
      <profile-picture class="is-100x100" :picture="picture" :initials="initials"></profile-picture>
      <div class="title">{{ fullName }}</div>
      <div class="subtitle">{{ subtitle }}</div>
      <div class="country-list block">
        <CountriesList :countries="countries" />
      </div>
    </header>

    <section class="modal-card-body">
      <div v-if="description">
        <div class="section-title" v-if="isNotTuvSpeaker">
          {{ $t("person_profile.user_biography") }}
        </div>
        <div class="section-text" v-html="description"></div>
      </div>

      <div class="mt-2" v-if="showContactList && contacts.length > 0">
        <template v-for="(contact, index) in contacts">
          <contact-link v-bind="contact" :key="index"></contact-link>
        </template>
      </div>
      <div class="mt-2" v-if="socialLinks.length > 0">
        <social-links class="contact-links" size="40" :links="socialLinks"></social-links>
      </div>
      <div v-if="agendaSessions.length > 0" class="mt-3">
        <agenda-session-pager-view :agenda-sessions="agendaSessions" :header-day-visible="true"></agenda-session-pager-view>
      </div>
    </section>
  </div>
</template>

<script>
import { buildContactList, buildSocialLinks, fullName, initials, positionAndCompany } from "@/shared/utils";
import SocialLinks from "@/web/components/shared/SocialLinks";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import ContactLink from "@/web/components/shared/ContactLink";
import AgendaSessionPagerView from "@/web/components/agenda/AgendaSessionPagerView";
import CountriesList from "@/web/components/profile/CountriesList";
import CloseIcon from "@/assets/icon_close.svg";
import {mapActions} from "vuex";
import dayjs from '@/shared/utils/day';

export default {
  name: "SpeakerProfileView",

  components: { AgendaSessionPagerView, ProfilePicture, SocialLinks, ContactLink, CountriesList },

  props: ["speaker", "eventId"],

  data() {
    return {
      refreshedSpeakerData: null,
    };
  },

  mounted() {
    if(this.eventId) {
      this.fetchById({eventId: this.eventId, componentId: this.speaker.event_component_id, speakerId: this.speaker.id})
        .then(result => {
          this.refreshedSpeakerData = result.data;
        });
    }
  },

  computed: {
    speakerData() {
      return this.refreshedSpeakerData || this.speaker;
    },

    fullName() {
      return fullName(this.speakerData);
    },

    picture() {
      return this.speakerData.picture;
    },

    description() {
      return this.speakerData.description && this.$options.filters.linkify(this.speakerData.description);
    },

    initials() {
      return initials(this.speakerData);
    },

    subtitle() {
      return positionAndCompany(this.speakerData);
    },

    countries() {
      return this.speakerData.country;
    },

    agendaSessions() {
      let agendaSessions;
      if (this.speaker.agenda_sessions && this.speaker.agenda_sessions.length) {
        agendaSessions = [...this.speaker.agenda_sessions];
      } else if (this.speakerData.agenda_sessions && this.speakerData.agenda_sessions.length) {
        agendaSessions = [...this.speakerData.agenda_sessions];
      } else {
        agendaSessions = [];
      }
      return agendaSessions.sort((a, b) => dayjs(a.time_start).isBefore(dayjs(b.time_start)) ? -1 : 1);
    },

    socialLinks() {
      return buildSocialLinks(this.speakerData);
    },

    contacts() {
      return buildContactList(this.speakerData);
    },

    showContactList() {
      return this.speakerData.show_public_data;
    },

    isNotTuvSpeaker() {
      return this.speakerData.event_component_id !== 11954;
    }
  },

  methods: {
    ...mapActions("speakers", ["fetchById"]),
  }
};
</script>
