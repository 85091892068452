import { Model } from "@vuex-orm/core";
import EventCustomFormModel from "@/web/store/models/EventCustomFormModel";
import EventAddonModel from "@/web/store/models/EventAddonModel";
import EventTicketBulkDiscountModel from "@/web/store/models/EventTicketBulkDiscountModel";

export default class EventTicketModel extends Model {
  static entity = "event_tickets";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      description: this.string(""),
      price: this.number(null),
      currency: this.string(""),
      tickets_left: this.number(null).nullable(),
      custom_form_id: this.attr(null),
      custom_form: this.belongsTo(EventCustomFormModel, "custom_form_id"),
      event_addon_ids: this.attr([]),
      event_addons: this.hasManyBy(EventAddonModel, "event_addon_ids"),
      event_ticket_bulk_discount_ids: this.attr([]),
      event_ticket_bulk_discounts: this.hasManyBy(EventTicketBulkDiscountModel, "event_ticket_bulk_discount_ids"),
    };
  }
}
