export function detectBrowserLanguage() {
  const language = normalize(navigator.languages && navigator.languages[0]);
  const navLanguage = normalize(navigator.language);
  const userLanguage = normalize(navigator.userLanguage);

  return language || navLanguage || userLanguage || "en";
}

function normalize(language) {
  if (language && language.includes("-")) {
    const splitted = language.split("-", 2);
    return splitted[0];
  }

  return language;
}

// Enable backward compatibility
export default detectBrowserLanguage;
