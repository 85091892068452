<template>
  <agenda-card :startTime="startTime" :endTime="endTime" :color="color" :hasCollapse="false" :is-small="isSmall" :selectable="selectable">
    <template>
      <h2 class="agenda-session-title" :class="isSmallClass">{{ title }}</h2>
      <p class="agenda-session-subtitle" :class="isSmallClass">{{ subtitle }}</p>
    </template>

    <template #actions>
      <div class="columns is-gapless is-vcentered">
        <!-- Video call link -->
        <div v-if="liveUrl" class="column is-narrow ml-1">
          <a
            class="agenda-session-stream-button"
            :class="isSmallClass"
            target="_blank"
            :href="liveUrl"
            @click.stop
            rel="noopener noreferrer"
          >
            <stream-icon class="svg-icon has-fill-primary"></stream-icon>
          </a>
        </div>
      </div>
    </template>
  </agenda-card>
</template>

<script>
import StreamIcon from "@/assets/icon_live_stream.svg";
import AgendaCard from "@/shared/components/agenda/AgendaCard";

export default {
  name: "AgendaSession",

  props: {
    session: {
      type: Object,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    StreamIcon,
    AgendaCard,
  },

  computed: {
    startTime() {
      return this.session.time_start;
    },

    endTime() {
      return this.session.time_end;
    },

    title() {
      return this.session.name;
    },

    subtitle() {
      return `${this.session.tag_agenda_place.value}, ${this.session.tag_agenda_path.value}`;
    },

    color() {
      return this.session.tag_agenda_path.hex;
    },

    description() {
      return this.session.description;
    },

    liveUrl() {
      return this.session.video_call_url;
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.has-text-primary {
  fill: var(--primary-color);
}

.agenda-section {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.content {
  white-space: pre-wrap;
}
</style>
