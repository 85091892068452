<template>
  <embed-youtube-video v-if="type === 'youtube'" v-bind="$props"></embed-youtube-video>
  <!-- <embed-vimeo-video v-else-if="type === 'vimeo'" v-bind="$props"></embed-vimeo-video> -->
</template>

<script>
import EmbedYoutubeVideo from "./EmbedYoutubeVideo";

const knownTypes = {
  youtube: /https?:\/\/((www\.)?youtube\.com|youtu\.be)\//
};

export default {
  name: "EmbedVideo",

  components: {
    EmbedYoutubeVideo
  },

  props: ["source"],

  computed: {
    type() {
      const url = this.source;
      return detectVideoType(url);
    }
  }
};

function detectVideoType(url) {
  for (const type in knownTypes) {
    const pattern = knownTypes[type];
    if (url.match(pattern)) {
      return type;
    }
  }

  return null;
}
</script>
