<template>
  <div class="field">
    <label class="label has-text-primary">{{ label }}</label>
    <div class="control">
      <template v-for="(item, index) in items">
        <div :key="item" class="is-flex is-flex-direction-row is-align-items-center mt-1">
          <radio-button
            class="mr-1"
            :is-primary="true"
            :value="isChecked(index)"
            @input="onCheckedChange(index)"
            :size="24"
          ></radio-button>
          <div class="label" style="cursor: pointer;" @click.self="onCheckedChange(index)">
            {{ item }}
          </div>
        </div>
      </template>
    </div>
    <template v-if="errorsVisible">
      <p v-if="requiredError" class="help is-danger">{{ $t("common.field_required_error") }}</p>
    </template>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import RadioButton from "@/shared/components/form/RadioButton";

export default {
  name: "RegisterRadioInput",
  components: { RadioButton },
  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: null,
    };
  },

  computed: {
    fieldId() {
      return this.fieldData.id;
    },

    requiredError() {
      const validationKey = FormBuilderConstants.VALIDATIONS.REQUIRED;
      return validationKey in this.validation && !this.validation[validationKey];
    },

    label() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_label`);
    },

    items() {
      return Object.values(this.$t(`${this.translationsNamespace}.${this.fieldData.id}_values`));
    },

    value() {
      if (this.selectedIndex !== null) {
        return this.items[this.selectedIndex];
      } else {
        return null;
      }
    },
  },

  methods: {
    onCheckedChange(index) {
      this.selectedIndex = index;
    },

    isChecked(index) {
      return this.selectedIndex === index;
    },
  },

  watch: {
    value(newValue) {
      this.$emit("input", newValue);
      this.$emit("form-input");
    },
  },
};
</script>

<style scoped></style>
