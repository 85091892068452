import { Model } from "@vuex-orm/core";
import EventTicketModel from "@/web/store/models/EventTicketModel";

export default class EventCustomFormModel extends Model {
  static entity = "event_custom_forms";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      type: this.string(""),
      json_config: this.string(""),
      event_tickets_ids: this.attr([]),
      event_tickets: this.hasManyBy(EventTicketModel, "event_tickets_ids"),
    };
  }
}
