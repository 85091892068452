import { Model } from "@vuex-orm/core";

export default class EventTicketBulkDiscountModel extends Model {
  static entity = "event_ticket_bulk_discounts";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      threshold: this.number(null),
      discount_amount: this.number(null),
    };
  }
}
