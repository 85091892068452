<template>
  <scrollable :items="days" :limit="3">
    <template v-slot="{ visibleItems }">
      <div class="columns has-days">
        <div class="column" :class="columnClass" v-for="(day, index) in visibleItems" :key="index">
          <button class="button is-fullwidth has-text-weight-bold" :class="activeClass(day)" @click="selectDay(day)">
            <span class="has-text-primary" style="margin-right: 4px">{{ day.only_date | date("ddd") }} &bullet;</span>
            <span>{{ day.only_date | date("D MMM") }}</span>
          </button>
        </div>
      </div>
    </template>
  </scrollable>
</template>

<script>
import Scrollable from "@/shared/components/common/Scrollable";
import {mapGetters, mapMutations} from "vuex";
import dayjs from '@/shared/utils/day';

export default {
  name: "BMDaySelect",

  components: {Scrollable},

  props: {
    days: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters("businessMatchingSuggestions", ["currentlySelectedDay"]),

    columnClass() {
      if (this.days.length === 1) {
        return "is-full";
      } else if (this.days.length === 2) {
        return "is-half";
      } else {
        return "is-one-third";
      }
    },
  },

  methods: {
    ...mapMutations("businessMatchingSuggestions", ["selectDay"]),

    activeClass(day) {
      if (day.only_date === (this.currentlySelectedDay && this.currentlySelectedDay.only_date)) {
        return "is-active";
      } else {
        return "";
      }
    },
  },

  watch: {
    currentlySelectedDay: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          if (!this.days.find(day => day.only_date === newValue.only_date)) {
            if (this.days.length) {
              this.selectDay(this.days[0]);
            }
          }
        } else {
          if (this.days.length) {
            this.selectDay(this.days[0]);
          }
        }
      },
    },
  },

  filters: {
    date(value, format) {
      return dayjs(value).format(format);
    },
  },
};
</script>

<style scoped lang="scss">
// Reverse text color on button active status
// Allows properly coloring the button
.button:active,
.button.is-active {
  .has-text-primary {
    color: #ffffff !important;
    color: var(--primary-color-text) !important;
  }
}
</style>
