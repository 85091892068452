export default {
  props: {
    redirect: {
      type: [String, Object],
      default: null
    }
  },

  methods: {
    nextRedirect(defaultRoute) {
      const nextRoute = this.redirectUrl || defaultRoute;
      if (nextRoute) {
        this.$router.replace(nextRoute);
      }
    },

    pushWithRedirect(defaultRoute) {
      const nextRoute = this.redirectUrl || defaultRoute;
      if (nextRoute) {
        this.$router.push(nextRoute);
      }
    }
  },

  computed: {
    redirectUrl() {
      const queryRedirect = this.$route.query.returnUrl;
      return this.redirect || queryRedirect || null;
    }
  }
};
