export function getRandomTempId(randomBytesLength) {
  if (typeof window === "undefined") {
    return new Date().getTime();
  }
  // 9 * 4/3 = 12
  // this is to avoid getting padding of a random byte string when it is base64 encoded
  let randomBytes;

  if (window.crypto && window.crypto.getRandomValues) {
    randomBytes = new Uint8Array(randomBytesLength);
    window.crypto.getRandomValues(randomBytes);
  } else return new Date().getTime();

  return window.btoa(String.fromCharCode(...randomBytes));
}
