<template>
  <div class="tag-content">
    <div class="tag-content-bar" :style="tagBarStyle" :class="isSmallClass"></div>
    <div class="tag-content-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TagContent",

  props: ["color", "isSmall"],

  computed: {
    tagBarStyle() {
      return {
        backgroundColor: this.color
      };
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    }
  }
};
</script>
