<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
      <div class="back-button" @click="back" v-if="backButtonVisible">
        <back-icon class="back-icon"></back-icon>
      </div>
      <div class="p-1"></div>
    </header>
    <section class="modal-card-body pb-2 pt-1 px-0">
      <template v-if="!selectedComponent">
        <b-m-components-list :components="components" @component-selected="selectComponent"
                             class="has-fullheight px-4"></b-m-components-list>
      </template>
      <template v-else-if="!selectedUserUuid">
        <component-attendees :component="selectedComponent" @user-selected="selectUser"
                             class="has-fullheight has-fullwidth"></component-attendees>
      </template>
      <template v-else>
        <component
          class="px-4"
          :is="createMeetingView"
          :user-uuid="selectedUserUuid"
          :component="selectedComponent"
          :meeting="meeting"
          @close="$parent.close()"
        ></component>
      </template>
    </section>
    <section class="modal-card-foot pb-1 pt-0"></section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import BackIcon from "@/assets/icon_back_arrow.svg";
import BMComponentsList from "@/web/components/businessmatching/BMComponentsList";
import ComponentAttendees from "@/web/components/attendees/ComponentAttendees";
import BMCreateFreeMeeting from "@/web/components/businessmatching/BMCreateFreeMeeting";
import BMCreateSemiMeeting from "@/web/components/businessmatching/BMCreateSemiMeeting";
import DropdownSelect from "@/shared/components/DropdownSelect";
import Constants from "@/web/constants";
import {mapActions, mapState} from "vuex";

export default {
  name: "BMModal",

  components: {CloseIcon, BackIcon, BMComponentsList, ComponentAttendees, DropdownSelect},

  props: {
    components: {
      type: Array,
      required: true,
    },

    userUuid: {
      type: String,
    },

    meeting: {
      type: Object,
    },
  },

  data() {
    return {
      selectedComponent: null,
      selectedUserUuid: null,
    };
  },

  computed: {
    ...mapState("currentUser", {currentUserUuid: "userUuid"}),

    createMeetingView() {
      const type = this.selectedComponent.additional_info.business_matching_type;
      if (type === Constants.BUSINESS_MATCHING_TYPE_SEMI) {
        return BMCreateSemiMeeting;
      } else if (type === Constants.BUSINESS_MATCHING_TYPE_FREE) {
        return BMCreateFreeMeeting;
      } else {
        throw Error("You can't create meeting for strict ride mode.");
      }
    },

    componentsSelectable() {
      return this.selectedComponent && this.components.length > 1;
    },

    userSelectable() {
      return this.selectedUserUuid && !this.userUuid;
    },

    backButtonVisible() {
      return this.userSelectable || this.componentsSelectable;
    },
  },

  methods: {
    ...mapActions("businessMatching", ["loadBusinessMatchingComponent"]),

    selectComponent(component) {
      this.selectedComponent = component;
      this.loadBusinessMatchingComponent(component.id);
    },

    selectUser(userUuid) {
      this.selectedUserUuid = userUuid;
    },

    back() {
      if (this.userSelectable) {
        this.selectedUserUuid = null;
      } else if (this.componentsSelectable) {
        this.selectedComponent = null;
      }
    },
  },

  watch: {
    components: {
      immediate: true,
      handler: function (newValue) {
        if (!newValue || newValue.length === 0) {
          this.$parent.close();
        } else if (newValue.length === 1) {
          this.selectComponent(newValue[0]);
        }
      },
    },

    userUuid: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.selectedUserUuid = newValue;
        }
      },
    },

    meeting: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.selectedUserUuid = newValue.from_user_id === this.currentUserUuid ? newValue.to_user_id : newValue.from_user_id;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;
  transition: 0.2s;

  .close-icon {
    stroke: #828282;
  }

  &:hover {
    background: #eeeeee;
  }
}

.back-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 64px;

  &:hover {
    background: #eeeeee;
  }

  .back-icon {
    fill: #828282;
  }
}

.modal-card {
  height: calc(100vh - 40px);
  min-height: calc(100vh - 40px);
}

@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    min-width: calc(100vh);
    margin: 0 auto;
  }
}
</style>
