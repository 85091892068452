<template>
  <div class="has-fullwidth">
    <div class="title">
      {{ $t("business_matching.user_choose_business_matching_component") }}
    </div>
    <b-m-component-list-item
      v-for="component in components"
      :key="component.id"
      :component="component"
      @click.native="onClick(component)"
    ></b-m-component-list-item>
  </div>
</template>

<script>
import BMComponentListItem from "@/web/components/businessmatching/BMComponentListItem";

export default {
  name: "BMComponentsList",

  components: {BMComponentListItem},

  props: {
    components: {
      type: Array,
      required: true,
    },
  },

  methods: {
    onClick(component) {
      this.$emit("component-selected", component);
    },
  },
};
</script>

<style scoped></style>
