<template>
  <div class="image">
    <img v-if="imageUrl" :src="imageUrl" />
    <span v-else>{{ initials }}</span>
  </div>
</template>

<script>
export default {
  name: "ProfilePicture",

  props: ["picture", "initials", "fontSize"],

  computed: {
    imageUrl() {
      return this.picture && this.picture.file_thumbnail_200_url;
    },
  }
};
</script>

<style lang="scss" scoped>
.image {
  background: var(--profile-picture-background-color);
  border-radius: 100%;
  line-height: 1;
  font-size: 22px;
  font-weight: var(--global-title-text-weight);
  color: var(--profile-picture-text-color);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;

  &.is-40x40 {
    font-size: 14px;
  }

  &.is-36x36 {
    font-size: 14px;
  }

  &.is-28x28 {
    font-size: 12px;
  }
}

.image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
