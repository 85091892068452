import { createScriptLoader } from "@/shared/utils";

const stripeUrl = "https://js.stripe.com/v3/";
// eslint-disable-next-line prettier/prettier
const stripeLoader = createScriptLoader(stripeUrl,
  publishableKey => new window.Stripe(publishableKey)
);

export async function loadStripe(publishableKey) {
  return await stripeLoader(publishableKey);
}

export async function loadElements(publishableKey) {
  if (!loadElements.elements) {
    loadElements.elements = loadStripe(publishableKey).then(stripe => stripe.elements());
  }

  return loadElements.elements;
}

export default {
  loadStripe,
  loadElements
};
