import Constants from "@/web/constants";

export function generateModulesFromComponentList(event, tickets, components) {
  const entranceWithTickets = event.entrance_with_tickets;
  let modules = components
    .reduce((acc, component) => {
      let module;
      if (component.component_name === Constants.QUIZZES_COMPONENT_TYPE) {
        module = {
          type: getModuleType(component.component_name),
          order: component.order,
          navbar_title: component.label,
          component_id: component.id,
          navbar_action: "scroll",
          visible: true,
        };
      } else if(component.component_name === Constants.BUSINESS_MATCHING_COMPONENT_TYPE) {
        module = {
          type: getModuleType(component.component_name),
          order: component.order,
          navbar_title: component.label,
          component_id: component.id,
          navbar_action: "page",
          visible: true,
        };
      } else {
        module = {
          type: getModuleType(component.component_name),
          order: component.order,
          navbar_title: component.label,
          component_id: component.id,
          visible: true,
        };
      }
      return [...acc, module];
    }, [])
    .filter(module => module.type !== null);

  //Only one agenda module can exist, agenda module will handle rest of them
  const agendaComponents = components.filter(agenda => agenda.component_name === Constants.AGENDA_COMPONENT_TYPE);
  if (agendaComponents.length) {
    modules = [...modules, generateAgendaModule(agendaComponents[0])];
  }

  if (entranceWithTickets && tickets.length > 0) {
    modules = [...modules, generateEventTicketsModule()];
  }
  //add remaining lp modules which are not related to the event components
  return [...modules, generateEventDescriptionModule(), generateMobileAppInfoModule()].sort((a, b) => a.order - b.order);
}

function getModuleType(componentName) {
  switch (componentName) {
    case Constants.EXHIBITORS_COMPONENT_TYPE:
      return Constants.MODULE_COMPONENT_EXHIBITORS;
    case Constants.ATTENDEES_COMPONENT_TYPE:
      return Constants.MODULE_ATTENDEES;
    case Constants.PARTNERS_COMPONENT_TYPE:
      return Constants.MODULE_COMPONENT_PARTNERS;
    case Constants.SPEAKERS_COMPONENT_TYPE:
      return Constants.MODULE_COMPONENT_SPEAKERS;
    case Constants.QUIZZES_COMPONENT_TYPE:
      return Constants.MODULE_QUIZZES;
    case Constants.FEED_WALL_COMPONENT_TYPE:
      return Constants.MODULE_FEED_WALL;
    case Constants.BUSINESS_MATCHING_COMPONENT_TYPE:
      return Constants.MODULE_BUSINESS_MATCHING;
    default:
      return null;
  }
}

function generateAgendaModule(agendaComponent) {
  return {
    type: Constants.MODULE_COMPONENT_AGENDA,
    order: agendaComponent.order,
    navbar_title: agendaComponent.label,
  };
}

function generateMobileAppInfoModule() {
  return {
    type: Constants.MODULE_MOBILE_APP_INFO,
    order: 999999,
  };
}

function generateEventDescriptionModule() {
  return {
    type: Constants.MODULE_EVENT_DESCRIPTION,
    order: -2,
  };
}

function generateEventTicketsModule() {
  return {
    type: Constants.MODULE_EVENT_TICKETS,
    order: -1,
    visible: true,
    // id: 0,
    only_for_guests: false,
    only_for_logged_in: false,
    pretax_price_visible: true,
    separator_disabled: false,
    subtitle: null,
    ticket_ids: [],
    // ticket_view_type: 'cinema',
  };
}

export function generateEventColors(event) {
  return {
    main_color: event.main_color,
    accent_color: event.main_color,
  };
}

export function isLiveWebinarUrl(url) {
  return url.includes("livewebinar.com");
}
