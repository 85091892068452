export default {
  data() {
    return {
      isRequesting: false,
      error: null
    };
  },

  beforeCreate() {
    const requests = this.$options.requests;

    for (const key in requests) {
      const request = requests[key];
      this[key] = makeRequest.bind(this, request);
    }
  }
};

// Private method used for request factory
async function makeRequest(request, ...args) {
  this.isRequesting = true;
  this.error = null;

  try {
    await request.apply(this, args);
  } catch (err) {
    console.error(err);
    this.error = err;
  } finally {
    this.isRequesting = false;
  }
}
